import React from "react";
import About from "./About.js";
import "./App.css";
import Home from "./Home.js";
import Navbar from "./Navbar.js";
import Projects from "./Projects.js";
import Work from "./Work.js";

function App() {
	return (
		<div className='App'>
			<Navbar />
			<Home />
			<About />
			<Work />
		</div>
	);
}

export default App;
