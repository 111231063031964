import React, { Component } from "react";
import headshot from "./headshot.jpg";

class About extends Component {
	headshot = () => {
		return <img src={headshot} alt='' className='headshot'></img>;
	};
	render() {
		return (
			<div className='section-container' id='about'>
				<div className='section-header'>About Me.</div>
				<div className='about-content-container'>
					<div className='about-container'>
						<div className='skills-content'>
							<span>
								Although my professional career has been focused on Project
								Management, there has always been an itch to program. Unable to
								shake the calling towards the Software Engineering world, I
								spent nights and weekends building my skillset. Turning an
								unshakeable passion into my career has been one of my proudest
								achievements thus far. Now as I continue to build upon this
								momentum, I am continually leveling up by immersing myself in
								growth minded surroundings.
								<br />
								<br />
								While I am a fullstack engineer, I definitely prefer working on
								the frontend. Delivering a quality user experience is the
								driving force behind my work. I enjoy embracing new trends and
								technologies all in the name of creating the highest quality
								product possible.
							</span>
						</div>
					</div>
					{this.headshot()}
				</div>
			</div>
		);
	}
}

export default About;
