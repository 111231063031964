import React, { useState, useEffect } from "react";

const Navbar = () => {
	const [navActive, setNavActive] = useState(false);
	const [prevScrollPos, setPrevScrollPos] = useState(0);
	const [visible, setVisible] = useState(true);

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.pageYOffset;
			setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
			setPrevScrollPos(currentScrollPos);
		};

		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	}, [prevScrollPos, visible]);

	const toggleNav = () => {
		setNavActive(!navActive);
		if (!navActive) {
			document.body.classList.add("navbar-active");
		} else {
			document.body.classList.remove("navbar-active");
		}
	};

	const burger = () => {
		let line1 = "line1";
		let line2 = "line2";
		let line3 = "line3";
		if (navActive) {
			line1 += " active";
			line2 += " active";
			line3 += " active";
		}
		return (
			<div className='burger' onClick={toggleNav}>
				<div className={line1}></div>
				<div className={line2}></div>
				<div className={line3}></div>
			</div>
		);
	};

	const navLinks = () => {
		let cls = "navbar-links";
		if (navActive) {
			cls += " active";
		}
		return (
			<ul className={cls}>
				<li>
					<a href='#home' onClick={toggleNav}>
						Home
					</a>
				</li>
				<li>
					<a href='#about' onClick={toggleNav}>
						About
					</a>
				</li>
				<li>
					<a href='#work' onClick={toggleNav}>
						Work
					</a>
				</li>
			</ul>
		);
	};

	return (
		<div className={`navbar ${visible ? "visible" : "hidden"}`}>
			<a href='#home' className='name' onClick={toggleNav}>
				Jordan Suero
			</a>
			{navLinks()}
			{burger()}
		</div>
	);
};

export default Navbar;
