import React, { useState } from "react";
import "./work.css";

const experiences = [
	{
		company: "Hoover Institution",
		title: "Software Developer",
		startDate: "10/2023",
		endDate: "Present",
		description: [
			"Managed end-to-end development of multiple software projects, overseeing project timelines, deliverables, and stakeholder communication",
			"Led the planning, execution, and delivery of software products, ensuring alignment with business objectives and stakeholder requirements",
			"Proactively identified and addressed potential project risks, ensuring smooth project execution and stakeholder satisfaction",
		],
	},
	{
		company: "Crowdstreet",
		title: "Software Engineer II",
		startDate: "03/2022",
		endDate: "03/2023",
		description: [
			"Maintained server-side logic pipelines that integrated with various data sources and complex APIs",
			"Worked with variety of legacy code in a variety of languages and frameworks such as Angular, Django, Python, and Java",
			"Contributed to in-house component library to streamline team development",
			"Collaborated with multi-disciplinary stakeholders to manage product launch timelines",
		],
	},
	{
		company: "Expedition Co",
		title: "Software Engineer",
		startDate: "09/2021",
		endDate: "03/2022",
		description: [
			"Team lead for frontend development on client facing product",
			"Contributed to multiple large scale projects utilizing different tech stacks including ReactNative, Typescript, and Redux",
			"Worked directly with clients to ensure project needs were met",
			"Created a template projects for team members to integrate quickly to our tech stack",
		],
	},
	{
		company: "Ambush Protected",
		title: "Software Engineer",
		startDate: "07/2020",
		endDate: "09/2021",
		description: [
			"Rapidly prototyped MVP for idea creation application and deployed in under a week",
			"Developed highly reusable React components",
			"Managed robust, testable and RESTful API's written in Node.js",
			"Focused on creating simple UI design dynamic web pages utilizing React with Redux",
			"Spearheaded design and deployment of 2.0 version of landing page",
		],
	},
];

const Work = () => {
	const [selectedExperienceIndex, setSelectedExperienceIndex] = useState(0);

	const handleExperienceSelect = (index) => {
		setSelectedExperienceIndex(index);
	};

	return (
		<div className='section-container' id='work'>
			<p className='section-header'>Work.</p>
			<div className='work-container'>
				<div className='menu-container'>
					<ul className='job-menu'>
						{experiences.map((experience, index) => (
							<li
								key={index}
								onClick={() => handleExperienceSelect(index)}
								className={index === selectedExperienceIndex ? "active" : ""}
							>
								{experience.company}
							</li>
						))}
					</ul>
					<div className='content'>
						<div className='experience-title'>
							{experiences[selectedExperienceIndex].title}
						</div>
						<div className='experience-dates'>
							{experiences[selectedExperienceIndex].startDate} -{" "}
							{experiences[selectedExperienceIndex].endDate}
						</div>
						<div className='experience-bullet-container'>
							{experiences[selectedExperienceIndex].description.map(
								(bullet, index) => {
									return (
										<div className='experience-bullet' key={index}>
											<div className='experience-bullet-arrow'>{">"}</div>
											{bullet}
										</div>
									);
								}
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Work;
