import React, { Component } from "react";
import awsIcon from "./aws.png";
import cryptocheck from "./cryptocheck.jpg";
import cssIcon from "./css3.png";
import flashy from "./flashy.jpg";
import fresh from "./fresh-reads.jpg";
import htmlIcon from "./html5.png";
import jsIcon from "./javascript.png";
import justfood from "./justfood.jpg";
import mongoIcon from "./mongodb.png";
import reactIcon from "./react.png";

class Projects extends Component {
	renderSkills = (skills) => {
		return (
			<div className='icon-container'>
				{skills.map((skill) => {
					return <img src={skill} alt='' className='project-icon' />;
				})}
			</div>
		);
	};

	renderProjects = () => {
		const projects = [
			{
				name: "Just Food",
				image: justfood,
				description:
					"A visual experience to solve the daily dilemma of what to eat.",
				skills: [jsIcon, reactIcon, cssIcon, htmlIcon, mongoIcon, awsIcon],
			},
			{
				name: "Cryptocheck",
				image: cryptocheck,
				description: "Check your cryptocurrency investments in real time.",
				skills: [jsIcon, reactIcon, cssIcon, htmlIcon],
			},
			{
				name: "Flashy",
				image: flashy,
				description: "Keep your studies simple using this flash card app.",
				skills: [jsIcon, reactIcon, cssIcon, htmlIcon],
			},
			{
				name: "Fresh Reads",
				image: fresh,
				description:
					"See all your stories from your favorite sites in one place.",
				skills: [jsIcon, reactIcon, cssIcon, htmlIcon],
			},
		];

		return projects.map(({ name, description, image, skills }) => {
			return (
				<div className='project-container'>
					<div className='project-overview'>
						<div className='project-name'>{name}</div>
						<div className='project-description'>{description}</div>
						{this.renderSkills(skills)}
					</div>
					<img src={image} alt='' className='mockup' />
				</div>
			);
		});
	};

	render() {
		return (
			<div className='section-container' id='projects'>
				<div className='section-header'>Projects.</div>
				<div className='projects-container'>{this.renderProjects()}</div>
			</div>
		);
	}
}

export default Projects;
