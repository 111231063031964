import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";

class ContactButtons extends Component {
	render() {
		return (
			<div className='contact-buttons'>
				<a href='https://www.linkedin.com/in/jtsuero'>
					<FontAwesomeIcon icon={faLinkedin} className='contact-logo' />
				</a>
				<a href='https://github.com/jtsuero'>
					<FontAwesomeIcon icon={faGithub} className='contact-logo' />
				</a>
				<div className='contact-buttons-line'></div>
			</div>
		);
	}
}

export default ContactButtons;
